import { Component, inject, input } from '@angular/core';
import { ToasterService } from '@desquare/services';
import { ClipboardModule } from 'ngx-clipboard';

@Component({
  standalone: true,
  imports: [ClipboardModule],
  selector: 'copy-to-clipboard',
  template: `
    @if(button()){
    <button
      ngxClipboard
      [cbContent]="cbcontent()"
      role="button"
      class="mx-1 float-end btn btn-sm btn-outline-success"
      ngbTooltip="Copy value"
      (click)="copyToaster(cbcontent())"
    >
      Copy value</button
    >} @else {
    <i
      ngxClipboard
      [cbContent]="cbcontent()"
      role="button"
      class="ri-file-copy-2-line pe-auto px-1 float-end copy-button"
      ngbTooltip="Copy value"
      (click)="copyToaster(cbcontent())"
    ></i>
    }
  `,
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent {
  toasterService = inject(ToasterService);
  cbcontent = input.required<string>();
  button = input<boolean>(false);

  copyToaster(value: string) {
    this.toasterService.success(`Copied ${value} to clipboard`);
  }
}

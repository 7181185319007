import {
  Component,
  input,
  inject,
  computed,
  ChangeDetectionStrategy,
  effect,
} from '@angular/core';
import { DeviceStatusCode } from '@desquare/models';
import { DeviceData } from '@desquare/types';
import _ from 'lodash';
import { lastValueFrom, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CurrentUserService,
  DeviceDataService,
  ToasterService,
} from '@desquare/services';
import { getNetworkAdaptersUtil } from '@desquare/utils';
import { environment } from '@desquare/environments';
import {
  AsyncPipe,
  NgClass,
  TitleCasePipe,
  UpperCasePipe,
} from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbPopoverModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MomentModule } from 'ngx-moment';
import { DateProxyPipe } from '../pipe/pipe/date-proxy.pipe';
import { CopyToClipboardComponent } from '../copy-to-clipboard/copy-to-clipboard.component';
import { WifiStrengthIndicatorComponent } from '../wifi-strength-indicator/wifi-strength-indicator.component';
import { UpdateDeviceGQL } from '@designage/gql';
import { DeviceMemoryComponent } from '../device-ui-components/device-memory.component';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    TranslateModule,
    TitleCasePipe,
    AsyncPipe,
    UpperCasePipe,
    NgbTooltip,
    NgbPopoverModule,
    MomentModule,
    DateProxyPipe,
    CopyToClipboardComponent,
    WifiStrengthIndicatorComponent,
    NgxSkeletonLoaderModule,
    DeviceMemoryComponent,
  ],
  selector: 'device-details-table',
  templateUrl: './device-details-table.component.html',
  styleUrls: ['./device-details-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceDetailsTableComponent {
  device = input.required<DeviceData>();
  currentUserService = inject(CurrentUserService);
  deviceDataService = inject(DeviceDataService);
  updateDeviceGQL = inject(UpdateDeviceGQL);
  toasterService = inject(ToasterService);

  // debugConsole = effect(() => {
  //   console.log('device', this.device());
  // });

  getNetworkAdaptersUtil = getNetworkAdaptersUtil;
  s3playlistsUrl = computed(() => {
    return `${
      environment.urls.dataBucket
    }/api/v1/playlists/device/${this.device()?.id.substring(0, 3)}/${
      this.device()?.id
    }.json`.toLowerCase();
  });

  miniMenuOpen = false;
  debugMiniMenuOpen = true;

  count$ = timer(0, 1000).pipe(
    // transform the stream to the result you want -- hh:mm:ss
    map((count) => this.realTimeClockFormat(count * 1000))
  );
  get isUserSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }

  getStatusEnum(statusEnum: number) {
    return DeviceStatusCode[statusEnum];
  }

  realTimeClockFormat(seconds: number) {
    return new Date(
      seconds + +new Date(this.device().deviceInfo?.currentTime?.currentDate)
    );
  }

  async updateAppletVersion(device: DeviceData) {
    const result = await lastValueFrom(
      this.updateDeviceGQL.mutate(
        {
          input: {
            id: device.id,
            name: device.name,
            channelId: device.channelId,
            appletVersion: device.signageOSDeviceTiming?.activeAppletVersion,
          },
        },
        {
          errorPolicy: 'ignore',
        }
      )
    );

    if (result.data?.updateDevice.isSuccessful) {
      this.toasterService.success('UPDATE_DEVICE_SUCCESSFUL');
    } else {
      this.toasterService.error('UPDATE_DEVICE_ERROR');
    }
    // this.deviceDataService.
  }
}
